module LedoApp.CurrencyReport.Services {
    "use strict";

    export class CurrencyReportService {
        private controller = "/api/currencyreport";

        constructor(public $log: ng.ILogService,
                    public $http: ng.IHttpService,
                    public config: any) {
        }

        public getCurrencyReport(): ng.IPromise<any> {
            const defer = this.$http.get(this.config.api + this.controller);
            return defer;
        }

        public saveCurrencyReport(data: Array<any>): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller, data);
            return defer;
        }
    }
}
angular
    .module("LedoApp.components.currencyreport")
    .service("CurrencyReportService", LedoApp.CurrencyReport.Services.CurrencyReportService);