module LedoApp.FirmCurrencyGrid.Services {
    "use strict";

    export class FirmCurrencyGridService {
        private controller = "/api/customizations/currency/get";
        private controller2 = "/api/customizations/currency/saves";

        constructor(public $log: ng.ILogService,
                    public $http: ng.IHttpService,
                    public config: any) {
        }

        public getFirmAccounts(taxYear: any, returnType: any, filter: any): ng.IPromise<any> {
            let queryString = "taxYear=" + taxYear + "&filter=" + filter + "&returnType=" + returnType;
            const defer = this.$http.get(this.config.api + this.controller + "?" + queryString);
            return defer;
        }

        public saveFirmCurrencyGrid(data: Array<any>, taxYear: any, returnType: any): ng.IPromise<any> {
            let queryString = "taxYear=" + taxYear + "&returnType=" + returnType
            const defer = this.$http.post(this.config.api + this.controller2 + "?" + queryString, data);
            return defer;
        }
    }
}

angular
    .module("LedoApp.components.firmcurrencygrid")
    .service("FirmCurrencyGridService", LedoApp.FirmCurrencyGrid.Services.FirmCurrencyGridService);