module Dashboard.ReturnTabs {
    "use strict";

    interface IDashboardAPIResponse {
        title: String;
        sections: Array<any>;
        bigBucketId: String;
        header: String;
        progressPercent: number;
    }

    interface IDashboardData {
        duration: String;
        UserRole: String;
        disableMyQuestionnaire: Boolean;
        data: IDashboardAPIResponse;
        mode: String;
        taxYear: String;
        progress: number;
        color: String;
    }

    class DashboardReturnTabComponentController implements IDashboardData {
        duration: String;
        UserRole: String;
        disableMyQuestionnaire: Boolean;
        data: IDashboardAPIResponse;
        mode: String;
        taxYear: String;
        progress: number;
        color: String;

        constructor(private $stateProvider: any,
                    //public interactiveReportService: InteractiveReportService,
                    public $stateParams: any,
                    private $ledoconfig: any,
                    // public $log: ng.ILogService,
                    private $scope: any,
                    private $helpersvc: any
                    // private $rootScope: ng.IRootScopeService,
                    // private $contextInject: any,
        ) {
            let that = this;
            this.$scope.$watch(that.mode, function (newVal: any, oldVal: any) {
                that.disableMyQuestionnaire = that.$helpersvc.isMyQuestionnaireDisabled(that.UserRole, newVal, that.$stateProvider.current.name);
            });
        }

        /* tslint:disable:no-unused-variable */
        private $onInit() {
            let that = this;
            this.duration = "1500";
            this.UserRole = this.$ledoconfig.getCookieStorage("persona");
            this.disableMyQuestionnaire = false;
            if (this.data.header) {
                this.data.header = this.data.header.trim();
                if (this.data.header.indexOf("&") > 0 && (this.data.header.indexOf(" &", -1) === this.data.header.length - 2)) {
                    this.data.header = this.data.header.substring(0, this.data.header.indexOf(" &", -1));
                }
            }

            this.taxYear = this.$ledoconfig.getCookieStorage("taxYear");
            this.progress = Math.round(this.data.progressPercent);
            this.color = (this.progress > 0) ? "#3eb2e6" : "#e1e1e1";
        }


        private navigateToBigBucket = function (id: any) {
            if (id) {
                this.$stateProvider.go('home.questionnaire', {
                    taxYear: this.taxYear,
                    ordering: id
                });
            }
        };

        private emptyOrNull = function (item: any) {
            return !(item.description === null || item.description.trim().length === 0)
        };

    }

   export class dashboardReturnTabComponent implements ng.IComponentOptions {
        public templateUrl: string;
        public controllerAs: string;
        public controller: any;
        public bindings: any;

        constructor() {
            this.bindings = {
                data: '=',
                mode: '='
            };
            this.templateUrl = "/app/shared/components/dashboardReturnTabItem/dashboardReturnTabItemComponent.html";
            this.controllerAs = "model";
            this.controller = [
                "$state",
                // "InteractiveReportService",
                "$stateParams",
                "$ledoconfig",
                "$scope",
                "$helpersvc",
                // "$rootScope",
                // "$contextInject",
                DashboardReturnTabComponentController];
        }
    }

}

angular.module("LedoApp.shared.components.dashboardreturntab")
    .component("dashboardTaxreturnTab", new Dashboard.ReturnTabs.dashboardReturnTabComponent());