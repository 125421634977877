module Dashboard.ReturnTabs {
    "use strict";


    interface IdashSectionCurrencyGroup {
        currency: String;
        total: Number;
        dropdownItems: Array<any>;
        length: Number;
        title: String;
    }

    interface ISection {
        bigBucket: String;
        count: String;
        description: String;
        smallBucket: String;
        total: Number;
        multiCurrency: Boolean;
        countCurrency: String;
        dashSectionCurrencyGroups: Array<IdashSectionCurrencyGroup>;

    }

    interface IDashboardData {
        index: String;
        section: ISection;
        first: String;
        mode: Boolean;
        UserRole: String;
        disableMyQuestionnaire: Boolean;
        taxYear: String;
        dropdownData: IdashSectionCurrencyGroup;
    }


    class DashSectionCurrencyGroup {
        currency: String;
        total: Number;
        dropdownItems: Array<any>;
        length: Number;
        title: String;

        constructor() {
            this.dropdownItems = new Array();
        }
    }


    class DashboardReturnTabDataComponentController implements IDashboardData {
        index: String;
        section: ISection;
        first: String;
        mode: Boolean;
        UserRole: String;
        disableMyQuestionnaire: Boolean;
        taxYear: String;
        dropdownData: IdashSectionCurrencyGroup;

        constructor(private $stateProvider: any,
                    //public interactiveReportService: InteractiveReportService,
                    public $stateParams: any,
                    private $ledoconfig: any,
                    // public $log: ng.ILogService,
                    private $scope: any,
                    private $helpersvc: any,
                    private $filter: any,
                    private currencyListService: any
                    // private $rootScope: ng.IRootScopeService,
                    // private $contextInject: any,
        ) {
        }

        /* tslint:disable:no-unused-variable */
        private $onInit() {
            let that = this;
            this.UserRole = this.$ledoconfig.getCookieStorage("persona");
            this.mode = false;
            this.taxYear = this.$ledoconfig.getCookieStorage("taxYear");

            that.dropdownData = new DashSectionCurrencyGroup();

            if (that.section.count == "#" && that.section.countCurrency != null && that.section.total > 0) {
                that.dropdownData.title = that.getCurrencyGlyphicon(that.section.countCurrency) + " " + that.$filter("currency")(this.section.total, "", 0) + " " + that.section.countCurrency;
            }
            else if (that.section.count != "#" && that.section.count != null && that.section.multiCurrency === false) {

                that.section.dashSectionCurrencyGroups.forEach((dashSectionCurrencyGroup) => {
                    that.dropdownData = dashSectionCurrencyGroup;
                });
                that.dropdownData.title = that.section.count;

            } else if (that.section.count == "#" && that.section.multiCurrency === false) {

                that.section.dashSectionCurrencyGroups.forEach((value) => {
                    that.dropdownData = value;

                    if (that.dropdownData.total != null) {
                        that.dropdownData.title = that.getCurrencyGlyphicon(that.dropdownData.currency) + " " + that.$filter("currency")(this.dropdownData.total, "", 0) + " " + that.dropdownData.currency;
                    } else {
                        that.dropdownData.title = String(that.section.total);
                    }


                });

            } else if (that.section.count != "#" && that.section.count != null && that.section.multiCurrency === true) {

                that.section.dashSectionCurrencyGroups.forEach((dashSectionCurrencyGroup) => {

                    dashSectionCurrencyGroup.dropdownItems.forEach((dropdownItem) => {
                        dropdownItem.value = that.$filter("currency")(dropdownItem.value, "", 0);
                        that.dropdownData.dropdownItems.push(dropdownItem);
                    });
                });
                that.dropdownData.title = that.section.count;
            }
            else if (that.section.count === "#" && that.section.multiCurrency === true) {
                that.section.dashSectionCurrencyGroups.forEach((dashSectionCurrencyGroup) => {

                    dashSectionCurrencyGroup.dropdownItems.forEach((dropdownItem) => {
                        dropdownItem.value = that.$filter("currency")(dropdownItem.value, "", 0);
                        that.dropdownData.dropdownItems.push(dropdownItem);
                    });
                });
                that.dropdownData.title = "Multiple Currency";
            }
        }


        private getCurrencyGlyphicon(currencyName: String) {
            let currency = {currencyCode: currencyName};
            return this.currencyListService.getCurrency(currency).currencyGlyph;
        }


        private navigateToQuestionnaire = function (item: any, section: any, loadNotification: Boolean) {
            if (!this.mode) {
                var param = {};
                var pathId = "questionnaire/path/" + this.taxYear + "/" + section.bigBucket + "/" + section.smallBucket + "/" + item.interestingField;
                var idObj = {
                    id: pathId,
                    pathGroup: item.pathGroup,
                    answerIndex: item.answerIndex,
                    wizardStep: item.wizardStep,
                    gridData: item.gridData,
                    ordering: item.ordering
                };

                if (!loadNotification || !Object.keys(idObj).length) {
                    param = {
                        taxYear: this.taxYear,
                        ordering: section.ordering
                    };
                    this.$stateProvider.go('home.questionnaire', param, {reload: false});
                }
                else {
                    param = {
                        taxYear: this.taxYear,
                        ordering: section.ordering,
                        loadNotification: loadNotification,
                        id: idObj
                    };
                    this.$stateProvider.go('home.questionnaire', param, {reload: false});
                }
            }
        }
    }

    export class dashboardReturnTabDataComponent implements ng.IComponentOptions {
        public templateUrl: string;
        public controllerAs: string;
        public controller: any;
        public bindings: any;

        constructor() {
            this.bindings = {
                index: '=',
                section: '=',
                first: '=',
                mode: '='
            };
            this.templateUrl = "/app/shared/components/dashboardReturnTabData/dashboardReturnTabDataComponent.html";
            this.controllerAs = "model";
            this.controller = [
                "$state",
                // "InteractiveReportService",
                "$stateParams",
                "$ledoconfig",
                "$scope",
                "$helpersvc",
                "$filter",
                "currencyListService",
                // "$rootScope",
                // "$contextInject",
                DashboardReturnTabDataComponentController];
        }
    }

}

angular.module("LedoApp.shared.components.dashboardreturntab")
    .component("dashboardTaxreturnTabData", new Dashboard.ReturnTabs.dashboardReturnTabDataComponent());