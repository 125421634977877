module LedoApp.Services {
    "use strict";

    export class InteractiveReportService {

        private controller = "/api/interactivereport";

        constructor(public $log: ng.ILogService,
                    public $http: ng.IHttpService,
                    public $contextInject: any,
                    public config: any) {
        }


        //following function will request all the changes made in MTI by taxpayer.
        public getAllTaxpayerChanges(): ng.IPromise<any> {
            const defer = this.$http.get(this.config.api + this.controller + "/getallchanges");
            return defer;
        }

        //following function will request all the changes made in MTI by preparer.
        public getAllPreparerChanges(): ng.IPromise<any> {
            const defer = this.$http.get(this.config.api + this.controller + "/getallchanges?preparerList=true");
            return defer;
        }

        public approveTaxpayerChange(deletedItem: any): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller + "/approvetaxpayerchange", deletedItem);
            return defer;
        }

        public rejectTaxpayerChange(deletedItem: any): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller + "/rejecttaxpayerchange", deletedItem);
            return defer;
        }

        public undoPreparerChange(deletedItem: any): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller + "/undopreparerchange", deletedItem);
            return defer;
        }

        public approveAllTaxpayerChanges(listOfAllChanges: any): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller + "/approvealltaxpayerchanges", listOfAllChanges);
            return defer;
        }

        public rejectAllTaxpayerChanges(listOfAllChanges: any): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller + "/rejectalltaxpayerchanges", listOfAllChanges);
            return defer;
        }

        public undoAllPreparerChanges(listOfAllChanges: any): ng.IPromise<any> {
            const defer = this.$http.post(this.config.api + this.controller + "/undoallpreparerchanges", listOfAllChanges);
            return defer;
        }
    }
}
angular
    .module("LedoApp.components.interactivereport")
    .service("InteractiveReportService", LedoApp.Services.InteractiveReportService);