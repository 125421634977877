///<reference path="firmcurrencygridservice.ts"/>

module LedoApp.FirmCurrencyGrid {
    "use strict";

    import FirmCurrencyGridService = LedoApp.FirmCurrencyGrid.Services.FirmCurrencyGridService;

    interface IFirmCurrency {
        context: WijmoControl
    }

    class WijmoControl {
        public flexGrid: any;
        public data: Array<any>;
        public expanded: boolean;
        public filtered: boolean;

        constructor() {
            this.flexGrid = null;
            this.data = [];
            this.expanded = true;
            this.filtered = true;
        }
    }

    class FirmCurrencyModel {
        public currencyModel: string;
        public firmId: string;
        public accountId: string;
        public enableCurrency: string;
        public isModified: boolean;
        public isPurchased: boolean;
    }

    class FilterItem {
        public name: string;
        public selectedName: string;
        public items: Array<Item>;
    }

    class Item {
        public name: string;
    }

    class Node implements Item {
        public name: string;

        constructor(name: string) {
            this.name = name;
        }
    }

    class DataNode implements FilterItem {
        public name: string;
        public selectedName: string;
        public items: Array<Item>;

        constructor(name: string, selectedName: string, items?: Array<Item>) {
            this.name = name;
            this.selectedName = selectedName;
            this.items = items || [];
        }

        public addItem(node: Item): void {
            this.items.push(node);
        }
    }


    declare var wijmo: any;

    class FirmCurrencyComponentController implements IFirmCurrency {
        public context: WijmoControl;
        public isLoaderBusy: boolean;
        private functionalCurrency: string;
        private isReadOnly: boolean;
        private treeData: any;
        private currencyList: any;
        private data: Array<FirmCurrencyModel>;
        private taxYear: string;
        private filter: any;

        constructor(private $stateProvider: any,
                    public firmCurrencyGridService: FirmCurrencyGridService,
                    public $stateParams: any,
                    private $ledoconfig: any,
                    private $timeout: any,
                    private $q: any,
                    private $ledomodal: any,
                    private $scope: any,
                    private currencyListService: any,
                    private FirmCustomizeService: any) {
            let that = this;
            that.context = new WijmoControl();
            that.$scope = $scope;
            that.isReadOnly = that.$ledoconfig.getCookieStorage("isReadOnly");
            that.$scope.$watch((): any => {
                    return that.FirmCustomizeService.context.get.taxYear();
                },
                (newValue: any, oldValue: any): void => {
                    that.onChangeFilterTaxYear(newValue.name, oldValue.name);
                },
            );
            that.$scope.$watch((): any => {
                    return that.FirmCustomizeService.context.get.returnType();
                },
                (newValue: any, oldValue: any): void => {
                    that.onChangeFilterTaxType(newValue.value, oldValue.value);
                },
            );
        }

        /* tslint:disable:no-unused-variable */
        private $onInit() {
            let that = this;
            that.functionalCurrency = that.$ledoconfig.getCookieStorage("functionalCurrency");
            that.taxYear = that.$ledoconfig.getCookieStorage("taxYear");

            // Don't load if invalid tax year wait for the filter to correctly set taxYear
            if (that.FirmCustomizeService.context.get.taxYear().name > 2016) {
                that.getFirmAccounts(
                    that.FirmCustomizeService.context.get.taxYear().name,
                    "All",
                    that.FirmCustomizeService.context.get.returnType().value);
                that.currencyListService.loadCurrencyListForAdmin(that.FirmCustomizeService.context.get.taxYear().name);
            }

            let tempList: Array<any> = that.currencyListService.getCurrencyData();
            let formattedList: Array<any> = [];

            if (tempList.length > 0) {
                tempList.forEach(item => {
                    formattedList.push({key: item.value.code, value: item.label})
                });
                that.currencyList = new wijmo.grid.DataMap(formattedList, "key", "value");
            }
            that.functionalCurrency = that.$ledoconfig.getCookieStorage("functionalCurrency");
        }

        private beginningEdit(s: any, e: any) {
            let that = this;
            let column = e.panel.columns[e.col];
            let item = e.panel.rows[e.row].dataItem;
            let oldVal = s.getCellData(e.row, e.col);
            let readOnly = null;

            that.data.forEach((rdata: any) => {
                if (rdata.accountId === "All") {
                    readOnly = rdata.enableCurrency;
                }
            });

            if (column.index !== 1) {
                if (!item.enableCurrency) {
                    e.cancel = true;
                }
            }
        }

        private itemFormatter(panel: any, r: any, c: any, cell: any) {
            let row = panel.rows[r];
            let col = panel.columns[c];
            let item: FirmCurrencyModel = row.dataItem;
        }

        private getFirmAccounts(taxYear: string, filter: string, returnType: string) {
            let that = this;
            let f = null;
            that.setBusyLoader(true);
            if (filter === "All") {
                f = "A";
            }
            else if (filter === "Enable Accounts") {
                f = "E";
            }
            else {
                f = "D"
            }
            let defer = that.firmCurrencyGridService.getFirmAccounts(taxYear, returnType, f);
            defer.then((response) => {
                that.data = response.data.currencyGetResponses;
                let firmCurrency: any = null;
                that.data.forEach((rdata: any) => {
                    if (rdata.accountId === "All") {
                        firmCurrency = rdata.currencyModel;
                    }
                });

                that.treeData = new wijmo.collections.CollectionView(that.data);
                that.context.data = that.treeData;
            }).catch((response) => {
                console.log("Error Response", response)
            }).finally(() => {
                that.$timeout(() => {
                    //that.setDataFilter(that.context.filtered);
                    that.setBusyLoader(false);
                });
            });
        }

        private gridInitialized(flexgrid: any, eventArgs: any) {
            let that = this;
            if (!flexgrid) {
                return;
            }

            that.filter = new wijmo.grid.filter.FlexGridFilter(flexgrid);
            that.filter.defaultFilterType = "Value";

            flexgrid.columns[1].dataMap = new wijmo.grid.DataMap([
                {key: true, value: "Enabled"},
                {key: false, value: "Disabled"},
            ], "key", "value");
            flexgrid.columns[1].showDropDown = false;

        }


        private isReadyOnly(item: any) {
            let that = this;
            let readOnly: boolean;
            that.data.forEach((rdata: any) => {
                if (rdata.accountId === "All") {
                    readOnly = !rdata.enableCurrency;
                }
            });
            return readOnly;
        }

        private saveGridData() {
            let that = this;
            let treeData: Array<FirmCurrencyModel> = that.data;
            that.submitApprovedChanges(treeData);
        }

        private onChangeFilterTaxYear(newValue: string, oldValue: string) {
            let that = this;
            if (newValue !== oldValue) {
                that.getFirmAccounts(newValue, "All", that.FirmCustomizeService.context.get.returnType().value);
            }
        }

        private onChangeFilterTaxType(newValue: string, oldValue: string) {
            let that = this;
            if (newValue !== oldValue) {
                that.getFirmAccounts(that.FirmCustomizeService.context.get.taxYear().name, "All", newValue);
            }
        }

        private onFilterItemChanged(newValue: string, oldValue: string) {
            let that = this;
            if (newValue !== oldValue) {
                that.getFirmAccounts(
                    that.FirmCustomizeService.context.get.taxYear().name,
                    newValue,
                    that.FirmCustomizeService.context.get.returnType().value);
            }
        }

        private submitApprovedChanges(data: Array<FirmCurrencyModel>) {
            let that = this;
            that.isLoaderBusy = true;
            let defer = that.firmCurrencyGridService.saveFirmCurrencyGrid(
                data,
                that.FirmCustomizeService.context.get.taxYear().name,
                that.FirmCustomizeService.context.get.returnType().value);
            defer.then(() => {
                //console.log("Save Successful");
            }).catch((errors) => {
                console.log("Error Response", errors);
            }).finally(() => {
                that.$timeout(() => {
                    that.isLoaderBusy = false;
                });
            });
        }

        private setBusyLoader(toggle: boolean) {
            let that = this;
            that.isLoaderBusy = toggle;
        }

        private getCurrencyEnumFromValue(value: string) {
            let that = this;
            for (let obj of that.currencyListService.getCurrencyData()) {
                if (obj.label === value) {
                    return obj.value.code;
                }
            }
        }

        private filterTaxYears(taxYears: Array<any>,
                               vm: any) {
            let that = this;
            let invalidTaxYear: boolean = that.FirmCustomizeService.context.get.taxYear().name <= 2016;
            let firstValidTaxYear: any = null;
            taxYears.forEach((taxYear) => {
                if (taxYear.name <= 2016) {
                    taxYear.visible = false;
                } else if (invalidTaxYear) {
                    firstValidTaxYear = taxYear;
                    invalidTaxYear = false;
                }
            });

            if (firstValidTaxYear != null) {
                vm.selectedTaxYear = firstValidTaxYear;
                that.FirmCustomizeService.context.set.taxYear(firstValidTaxYear);
                that.getFirmAccounts(
                    that.FirmCustomizeService.context.get.taxYear().name,
                    "All",
                    that.FirmCustomizeService.context.get.returnType().value);

                that.currencyListService.loadCurrencyListForAdmin(that.FirmCustomizeService.context.get.taxYear().name);
            }
        }

        private cellEditEnding(s: any, e: any) {
            let that = this;
            let oldVal = s.getCellData(e.row, e.col);
            let newVal1 = s.activeEditor.value;
            let newVal = s.activeEditor.checked;

            let column = e.panel.columns[e.col];
            let item = e.panel.rows[e.row].dataItem;
            let saveData: Array<any> = [];

            if (column.index === 1) {
                if (item.accountId === "All") {
                    that.data.forEach((row: any) => {
                        if (!row.isModified) {
                            row.enableCurrency = newVal;
                            if (!newVal) {
                                row.currencyModel = "USD";
                            }
                        }
                    });
                }
                else {
                    if (!newVal) {
                        item.currencyModel = "USD";
                        item.isModified = true;
                    }
                    if (newVal) {
                        item.isModified = true;
                    }

                    item.enableCurrency = newVal;
                }
            }
            else if (column.index === 2) {
                if (newVal1 !== "") {
                    if (item.accountId === "All") {
                        that.data.forEach(rdata => {
                            //Find the whether it is modfied for derived
                            if (!rdata.isModified && rdata.enableCurrency) {
                                rdata.currencyModel = that.getCurrencyEnumFromValue(newVal1);
                            }
                        });
                    }
                    else {
                        item.isModified = true;
                    }
                }
                else {
                    e.cancel = true;
                }
            }
        }
    }

    export class FirmCurrencyGridComponent implements ng.IComponentOptions {
        public templateUrl: string;
        public controllerAs: string;
        public controller: any;

        constructor() {
            this.templateUrl = "/app/components/firmcustomization/FirmCurrencyGrid/firmcurrencygrid.html";
            this.controllerAs = "model";
            this.controller = ["$state",
                "FirmCurrencyGridService",
                "$stateParams",
                "$ledoconfig",
                "$timeout",
                "$q",
                "$ledomodal",
                "$scope",
                "currencyListService",
                "FirmCustomizeService",
                FirmCurrencyComponentController];
        }
    }

}

angular.module("LedoApp.components.firmcurrencygrid")
    .component("firmCurrencyGridComponent", new LedoApp.FirmCurrencyGrid.FirmCurrencyGridComponent());