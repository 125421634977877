module Dashboard.ReturnTabs {
    "use strict";

    interface IDashboardAPIResponse {
        title: String;
        sections: Array<any>;
        bigBucketId: String;
        header: String;
        progressPercent: number;
    }

    interface IDashboardData {
        tabData: any;
    }

    class DashboardReturnTabsComponentController implements IDashboardData {
        tabData: any;

        constructor(private $stateProvider: any,
                    //public interactiveReportService: InteractiveReportService,
                    public $stateParams: any,
                    private $ledoconfig: any,
                    // public $log: ng.ILogService,
                    private $scope: any,
                    private $helpersvc: any
                    // private $rootScope: ng.IRootScopeService,
                    // private $contextInject: any,
        ) {
        }

        /* tslint:disable:no-unused-variable */

    }

   export class dashboardReturnTabsComponent implements ng.IComponentOptions {
        public templateUrl: string;
        public controllerAs: string;
        public controller: any;
        public bindings: any;

        constructor() {
            this.bindings = {
                tabData: '='
            };
            this.templateUrl = "/app/shared/components/dashboardReturnTabs/dashboardreturntabs.html";
            this.controllerAs = "model";
            this.controller = [
                "$state",
                // "InteractiveReportService",
                "$stateParams",
                "$ledoconfig",
                "$scope",
                "$helpersvc",
                // "$rootScope",
                // "$contextInject",
                DashboardReturnTabsComponentController];
        }
    }
}

 angular.module("LedoApp.shared.components.dashboardreturntab")
     .component("dashboardTaxreturnTabs", new Dashboard.ReturnTabs.dashboardReturnTabsComponent());