///<reference path="currencyreportservice.ts"/>

module LedoApp.CurrencyReport {
    "use strict";

    import CurrencyReportService = LedoApp.CurrencyReport.Services.CurrencyReportService;

    interface ICurrencyReport {
        context: WijmoControl
    }

    class WijmoControl {
        public flexGrid: any;
        public data: Array<any>;
        public expanded: boolean;
        public filtered: boolean;

        constructor() {
            this.flexGrid = null;
            this.data = [];
            this.expanded = true;
            this.filtered = true;
        }
    }

    class CurrencyModel {
        public currencyCode: string;
        public rateOfExchange: number;
        public exchangeRateType: string;
        public exchangeSpotDate: string;
        public preparerApproved: boolean;
    }

    class NavigationInfo {
        public bigBucket: string;
        public smallBucket: string;
        public questionnairePathId: string;
        public ordering: string;
        public gridData: boolean;
        public wizardStep: number;
        public interestingField: string;
        public pathGroup: Array<number> = [];
        public answerIndex: Array<number> = [];
    }

    class CurrencyReportEntryDto {
        public currency: CurrencyModel;
        public title: string;
        public amount: number;
        public answerTag: string;
        public records: Array<CurrencyReportEntryDto>;
        public navigationInfo: NavigationInfo;
        public isInherited: boolean;
        public questionId: string;
        public hasVisibleChildren: boolean;
        public isSectionHeader: boolean;
    }

    declare var wijmo: any;

    class CurrencyReportComponentController implements ICurrencyReport {
        public context: WijmoControl;
        public isLoaderBusy: boolean;
        private treeData: any;
        private rateDropdownValues: any;
        private currencyList: any;
        private currencyGlyph: any;
        private functionalCurrency: string;
        private validCurrencyDatesMap: any;
        private datePickerReloaded: boolean;
        private oldValue: any;
        private data: Array<CurrencyReportEntryDto>;
        private hasDirtyAnswers: boolean;
        private isReadOnly: boolean;
        private visibleRowNumbers: { [rowIndex: number]: number } = {};

        constructor(private $stateProvider: any,
                    public currencyReportService: CurrencyReportService,
                    public $stateParams: any,
                    private $ledoconfig: any,
                    private $timeout: any,
                    private $q: any,
                    private config: any,
                    private currencyListService: any) {
            let that = this;
            that.context = new WijmoControl();
            that.validCurrencyDatesMap = {};
            that.datePickerReloaded = false;
            that.isReadOnly = that.$ledoconfig.getCookieStorage("isReadOnly");
        }

        /* tslint:disable:no-unused-variable */
        private $onInit() {
            let that = this;
            that.getCurrencyReport();
            that.rateDropdownValues = new wijmo.grid.DataMap(that.config.enums.currencyRateTypes, "key", "value");

            let tempList: Array<any> = that.currencyListService.getCurrencyData();
            let formattedList: Array<any> = [];

            if (tempList.length > 0) {
                tempList.forEach(item => {
                    formattedList.push({key: item.value.code, value: item.label})
                });
                that.currencyList = new wijmo.grid.DataMap(formattedList, "key", "value");
            }

            that.functionalCurrency = that.$ledoconfig.getCookieStorage("functionalCurrency");
            that.currencyGlyph = that.currencyListService.getCurrency(that.functionalCurrency).currencyGlyph;
        }

        private getCurrencyReport() {
            let that = this;
            that.setBusyLoader(true);

            let defer = that.currencyReportService.getCurrencyReport();
            defer.then((response) => {
                that.data = response.data.records;

                let defaultCurrency: CurrencyReportEntryDto = null;
                for (let obj of that.data) {
                    if (obj.title === "Default Currency") {
                        defaultCurrency = obj;
                        break;
                    }
                }

                that.iterateAndSetInheritedCurrency(that.data, defaultCurrency);
                that.treeData = new wijmo.collections.CollectionView(that.data);
                that.context.data = that.treeData;
                that.hasDirtyAnswers = that.dirtyAnswersExist(that.data);
            }).catch((response) => {
                console.log("Error Response", response)
            }).finally(() => {
                that.$timeout(() => {
                    that.setDataFilter(that.context.filtered);
                    that.setBusyLoader(false);
                });
            });
        }

        private hasData(): boolean {
            let that = this;

            return (that.context.flexGrid.rows) && that.context.flexGrid.rows.some((row: any) => row.isVisible);
        }

        private shouldShowAmount(item: any): boolean {
            let that = this;
            return item.amount > 0 &&
                (!that.context.filtered ||
                    (item.currency &&
                        item.currency.currencyCode !== that.functionalCurrency));
        }

        private getCurrencyGlyph(item: CurrencyReportEntryDto) {
            let that = this;
            return that.currencyListService.getCurrency(item.currency).currencyGlyph;
        }

        private setDataFilter(filter: boolean) {
            let that = this;
            let rows = that.context.flexGrid.rows;
            let idxCounter = 1;
            let hasVisibleRecords = function (data: Array<CurrencyReportEntryDto>): boolean {
                let hasRecords: boolean;
                for (let record of data) {
                    hasRecords = (record.amount > 0) &&
                        (filter ? (record.currency && record.currency.currencyCode !== that.functionalCurrency)
                            : true);

                    if (record.records && record.records.length > 0) {
                        hasRecords = hasRecords || hasVisibleRecords(record.records);
                    }

                    if (hasRecords) {
                        break;
                    }
                }
                return hasRecords;
            };

            rows.forEach((row: any) => {
                let item: CurrencyReportEntryDto = row.dataItem;
                let hasVisibleChildren = false;
                if (item.records && item.records.length > 0) {
                    hasVisibleChildren = hasVisibleRecords(item.records);
                    item.hasVisibleChildren = hasVisibleChildren;
                }

                row.visible = hasVisibleChildren || ((item.amount > 0 || item.title === "Default Currency") &&
                    (filter ? (item.currency && item.currency.currencyCode !== that.functionalCurrency) : true));

                if (row.visible) {
                    that.visibleRowNumbers[rows.indexOf(row)] = idxCounter++;
                }
            })
        }

        private iterateAndSetInheritedCurrency(data: Array<CurrencyReportEntryDto>, defaultCurrency: CurrencyReportEntryDto) {
            let that = this;
            for (let obj of data) {
                if (!obj.isSectionHeader && obj.isInherited && defaultCurrency != null) {
                    obj.currency = defaultCurrency.currency;
                }

                that.iterateAndSetInheritedCurrency(obj.records, obj.isSectionHeader ? defaultCurrency : obj);
            }
        }

        private getComputedCurrency(item: any) {
            if (item.currency != null && item.currency.rateOfExchange > 0) {
                return Math.round(item.amount * item.currency.rateOfExchange);
            } else {
                return "";
            }
        }

        private gridInitialized(flexgrid: any, eventArgs: any) {
            if (!flexgrid) {
                return;
            }
        }

        private itemFormatter(panel: any, r: any, c: any, cell: any) {
            let row = panel.rows[r];
            let col = panel.columns[c];
            let item: CurrencyReportEntryDto = row.dataItem;

            let css: Array<string> = [];

            if (panel.cellType !== panel.grid.columnHeaders.cellType) {
                switch (col.header) {
                    case "Spot Rate Date":
                        css.push("cell-align-center");
                        break;
                    case "Section":
                        if (!item.hasVisibleChildren) {
                            css.push("cell-hide-hierarchy");
                        }
                        break;
                    case "Conversion Result":
                        css.push("cell-align-right");
                        break;
                }

                if (row.level === 0 || (item && item.hasVisibleChildren)) {
                    css.push("cell-parent-node");
                }

                // This is the last column, need to apply for checkmarks
                if (panel.columns.length - 1 === c) {
                    css.push("cell-align-center");
                }
            } else if (panel.cellType === panel.grid.columnHeaders.cellType) {
                css.push("header-cell");
            }

            if (css && css.length > 0) {
                cell.className = cell.className + " " + css.join(" ");
            }

            // Set all rows to editable
            row.isReadOnly = (item) ? item.isSectionHeader : false;
        }

        private beginningEdit(s: any, e: any) {
            let column = e.panel.columns[e.col];
            let item = e.panel.rows[e.row].dataItem;

            switch (column.header) {
                case "Spot Rate Date":
                    e.cancel = item.currency.exchangeRateType !== "SPOT_RATE";
                    break;
            }
        }

        private cellEditEnding(s: any, e: any) {
            let that = this;
            let oldVal = s.getCellData(e.row, e.col);
            let newVal = s.activeEditor.value;
            let column = e.panel.columns[e.col];
            let item = e.panel.rows[e.row].dataItem;

            if (!e.cancel) {
                if ((oldVal === newVal) ||
                    (column.header === "Exchange Rate Type" && that.getRateEnumFromValue(newVal) === oldVal) ||
                    (column.header === "Currency" && that.getCurrencyEnumFromValue(newVal) === oldVal)) {
                    e.cancel = true;
                } else {
                    if (item.isInherited) {
                        item.isInherited = false;
                        item.currency = angular.copy(item.currency);
                        that.iterateAndSetInheritedCurrency(item.records, item);
                    }

                    that.oldValue = angular.copy(item);
                    item.currency.preparerApproved = false;
                    that.hasDirtyAnswers = true;
                }
            }
        }

        private cellEditEnded(s: any, e: any) {
            if (!e.cancel) {
                let that = this;
                let column = e.panel.columns[e.col];
                let item = e.panel.rows[e.row].dataItem;

                switch (column.header) {
                    case "Exchange Rate Type":
                    case "Currency":
                    case "Spot Rate Date":
                        that.updateCurrency(item);
                        break;
                }
            }
        }

        private setBusyLoader(toggle: boolean) {
            let that = this;
            that.isLoaderBusy = toggle;
        }

        private updateCurrency(item: any) {
            let that = this;
            let isChanged = false;
            let currencyUpdate = function () {
                that.currencyListService.getExchangeRate(
                    item.currency.exchangeRateType,
                    item.currency.currencyCode,
                    item.currency.exchangeSpotDate)
                    .then((response: any) => {
                        if (response.data.exchangeRate) {
                            item.currency.rateOfExchange = response.data.exchangeRate;
                        } else {
                            item.currency = that.oldValue.currency;
                        }
                    })
                    .finally(() => {
                        that.$timeout(() => {
                            that.context.flexGrid.refresh();
                            that.setBusyLoader(false);
                            console.log("isChanged --> " + isChanged);
                            if (isChanged) {
                                let data: Array<any> = [];
                                data.push(item);
                                that.submitApprovedChanges(data);
                            }
                        });
                    });
            };

            that.setBusyLoader(true);

            if (item.currency.exchangeRateType === "SPOT_RATE") {
                that.getCurrencyDates(item)
                    .then((currencyDates: any) => {
                        if (!item.currency.exchangeSpotDate ||
                            currencyDates.validDates.indexOf(item.currency.exchangeSpotDate) === -1) {
                            if (that.oldValue && that.oldValue.currency && that.oldValue.currency.exchangeSpotDate) {
                                item.currency.exchangeSpotDate = that.oldValue.currency.exchangeSpotDate;
                            } else {
                                item.currency.exchangeSpotDate = currencyDates.yearEndDate;
                                isChanged = true;
                            }
                        } else {
                            isChanged = true;
                        }
                    }).finally(currencyUpdate);
            } else {
                isChanged = true;
                currencyUpdate();
            }
        }

        private approveChanges() {
            let that = this;
            if (!that.isReadOnly) {
                that.iterateAndApproveChanges(that.data);
                that.hasDirtyAnswers = that.dirtyAnswersExist(that.data);
                that.submitApprovedChanges(that.data);
            }
        }

        private approveChange(item: CurrencyReportEntryDto) {
            let that = this;
            if (!that.isReadOnly) {
                let data: Array<any> = [];
                data.push(item);
                that.iterateAndApproveChanges(data);
                that.hasDirtyAnswers = that.dirtyAnswersExist(that.data);
                that.submitApprovedChanges(data);
            }
        }

        private jumpToPage(nav: NavigationInfo) {
            let that = this;
            let idObj = {
                id: nav.questionnairePathId,
                pathGroup: nav.pathGroup,
                answerIndex: nav.answerIndex,
                wizardStep: nav.wizardStep,
                ordering: nav.ordering,
            };

            let param = {
                taxYear: that.$ledoconfig.getCookieStorage("taxYear"),
                ordering: nav.ordering,
                loadNotification: true,
                id: idObj,
            };
            that.$stateProvider.go("home.questionnaire", param, {reload: true});
        }

        private iterateAndApproveChanges(data: Array<CurrencyReportEntryDto>) {
            let that = this;
            for (let obj of data) {
                if (!obj.isSectionHeader) {
                    if (!obj.currency.preparerApproved) {
                        obj.currency.preparerApproved = true;
                    }
                }
                that.iterateAndApproveChanges(obj.records);
            }
        }

        private dirtyAnswersExist(data: Array<CurrencyReportEntryDto>): boolean {
            let that = this;
            let value = false;
            for (let obj of data) {
                if (value) {
                    break;
                }

                if (obj.isSectionHeader) {
                    value = that.dirtyAnswersExist(obj.records);
                    continue;
                }

                value = !obj.currency.preparerApproved || that.dirtyAnswersExist(obj.records);
            }

            return value;
        }

        private submitApprovedChanges(data: Array<CurrencyReportEntryDto>) {
            let that = this;
            if (!that.isReadOnly) {
                that.isLoaderBusy = true;
                let defer = that.currencyReportService.saveCurrencyReport(data);
                defer.then((response) => {
                    console.log("Success Response", response);
                }).catch((errors) => {
                    console.log("Error Response", errors);
                }).finally(() => {
                    that.isLoaderBusy = false;
                });
            }
        }

        private toggleCollapse() {
            let that = this;
            that.context.expanded = !that.context.expanded;
            if (!that.context.expanded) {
                that.context.flexGrid.collapseGroupsToLevel(0);
            } else {
                that.context.flexGrid.collapseGroupsToLevel(that.context.flexGrid.rows.maxGroupLevel + 1);
            }
        };

        private getRateEnumFromValue(value: string) {
            let that = this;
            for (let obj of that.config.enums.currencyRateTypes) {
                if (obj.value === value) {
                    return obj.key;
                }
            }
        }

        private getCurrencyEnumFromValue(value: string) {
            let that = this;
            for (let obj of that.currencyListService.getCurrencyData()) {
                if (obj.label === value) {
                    return obj.value.code;
                }
            }
        }

        private getCurrencyDates(item: CurrencyReportEntryDto) {
            let that = this;
            let deferred = that.$q.defer();
            if (!that.validCurrencyDatesMap[item.currency.currencyCode]) {
                that.currencyListService.getValidCurrencyDates(item.currency.currencyCode)
                    .then(function (data: any) {
                        let obj: any = {
                            validDates: [],
                            yearEndDate: "",
                        };
                        let dateLength = data.data.validDates.length;
                        while (dateLength--) {
                            obj.validDates.push(data.data.validDates[dateLength]);
                        }
                        if (obj.validDates.length > 0) {
                            obj.yearEndDate = obj.validDates.sort()[obj.validDates.length - 1];
                        }

                        that.validCurrencyDatesMap[item.currency.currencyCode] = obj;
                        deferred.resolve(obj);
                    });
            } else {
                deferred.resolve(that.validCurrencyDatesMap[item.currency.currencyCode]);
            }

            return deferred.promise;
        }
    }

    export class CurrencyReportComponent implements ng.IComponentOptions {
        public templateUrl: string;
        public controllerAs: string;
        public controller: any;

        constructor() {
            this.templateUrl = "/app/components/CurrencyReport/currencyreport.html";
            this.controllerAs = "model";
            this.controller = ["$state",
                "CurrencyReportService",
                "$stateParams",
                "$ledoconfig",
                "$timeout",
                "$q",
                "config",
                "currencyListService",
                CurrencyReportComponentController];
        }
    }

}

angular.module("LedoApp.components.currencyreport")
    .component("currencyReportComponent", new LedoApp.CurrencyReport.CurrencyReportComponent());


