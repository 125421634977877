///<reference path="interactivereportservice.ts"/>
module LedoApp.DeletedItemReport {
    "use strict";
    
    import InteractiveReportService = LedoApp.Services.InteractiveReportService;

    interface IInteractiveReport {
        taxpayerReport: Array<any>;
        preparerReport: Array<any>;
        isTaxpayerLoaderBusy: Boolean;
        isPreparerLoaderBusy: Boolean;
    }

    class InteractiveReportComponentController implements IInteractiveReport {
        public taxpayerReport: Array<any>;
        public preparerReport: Array<any>;
        public isTaxpayerLoaderBusy: Boolean;
        public isPreparerLoaderBusy: Boolean;

        constructor(private $stateProvider: any,
                    public interactiveReportService: InteractiveReportService,
                    public $stateParams: any,
                    private $ledoconfig: any,
                    // public $log: ng.ILogService,
                    // private $scope: IBodyMainScope,
                    // private $rootScope: ng.IRootScopeService,
                    // private $contextInject: any,
        ) {
            this.taxpayerReport = [];
            this.preparerReport = [];
            this.isTaxpayerLoaderBusy = true;
            this.isPreparerLoaderBusy = true;
        }

        /* tslint:disable:no-unused-variable */
        private $onInit() {
            let that = this;
            that.getAllTaxpayerChanges();
            that.getAllPreparerChanges();
        }


        private getAllTaxpayerChanges() {
            let that = this;
            let defer = that.interactiveReportService.getAllTaxpayerChanges();
            that.isTaxpayerLoaderBusy = true;
            defer.then(function (response) {
                that.isTaxpayerLoaderBusy = false;
                if (response.data.status === "success") {
                    that.taxpayerReport = response.data.interactiveReport;
                }
            }).catch((response) => {
                console.log("Error response", response);
            });
        }

        private getAllPreparerChanges() {
            let that = this;
            let defer = that.interactiveReportService.getAllPreparerChanges();
            that.isPreparerLoaderBusy = true;
            defer.then(function (response) {
                that.isPreparerLoaderBusy = false;
                if (response.data.status === "success") {
                    that.preparerReport = response.data.interactiveReport;
                }
            }).catch((response) => {
                console.log("Error response", response);
            });
        }


        private approveTaxpayerChange(deletedItem: any) {
            let that = this;
            let defer = that.interactiveReportService.approveTaxpayerChange(deletedItem);
            that.isTaxpayerLoaderBusy = true;
            defer.then(function (response) {
                that.isTaxpayerLoaderBusy = false;
                if (response.data.status === "success") {
                    //get the updated list of changes after a successfull approval
                    that.getAllTaxpayerChanges();
                }
            }).catch((response) => {
                console.log("Error response", response);
            });
        }

        private rejectTaxpayerChange(deletedItem: any) {
            let that = this;
            let defer = that.interactiveReportService.rejectTaxpayerChange(deletedItem);
            that.isTaxpayerLoaderBusy = true;
            defer.then(function (response) {
                that.isTaxpayerLoaderBusy = false;
                that.isTaxpayerLoaderBusy = false;
                if (response.data.status === "success") {
                    //get the updated list of changes after a successfull approval
                    that.getAllTaxpayerChanges();
                }
            }).catch((response) => {
                console.log("Error response", response);
            });
        }

        private undoPreparerChange(deletedItem: any) {
            let that = this;
            let defer = that.interactiveReportService.undoPreparerChange(deletedItem);
            that.isPreparerLoaderBusy = true;
            defer.then(function (response) {
                that.isPreparerLoaderBusy = false;
                if (response.data.status === "success") {
                    //get the updated list of changes after a successfull approval
                    that.getAllPreparerChanges();
                }
            }).catch((response) => {
                console.log("Error response", response);
            });
        }

        private approveAllTaxpayerChanges(listOfAllChanges: any) {
            let that = this;
            let defer = that.interactiveReportService.approveAllTaxpayerChanges(listOfAllChanges);
            that.isTaxpayerLoaderBusy = true;
            defer.then(function (response) {
                that.isTaxpayerLoaderBusy = false;
                if (response.data.status === "success") {
                    //get the updated list of changes after a successfull approval
                    that.getAllTaxpayerChanges();
                }
            }).catch((response) => {
                console.log("Error response", response);
            });
        }

        private rejectAllTaxpayerChanges(listOfAllChanges: any) {
            let that = this;
            let defer = that.interactiveReportService.rejectAllTaxpayerChanges(listOfAllChanges);

            defer.then(function (response) {
                if (response.data.status === "success") {
                    //get the updated list of changes after a successfull approval
                    that.getAllTaxpayerChanges();
                }
            }).catch((response) => {
                console.log("Error response", response);
            });
        }

        private undoAllPreparerChanges(listOfAllChanges: any) {
            let that = this;
            let defer = that.interactiveReportService.undoAllPreparerChanges(listOfAllChanges);

            defer.then(function (response) {
                if (response.data.status === "success") {
                    //get the updated list of changes after a successfull approval
                    that.getAllPreparerChanges();
                } else {
                    // that.$rootScope.addAlert("danger", error);
                    // that.updateToggleStatesGui(toggleStates, !value);
                }
            }).catch((response) => {
                console.log("Error response", response);
            });
        }


        private gotoQuestion(questionInfo: any) {
            let navInfo = questionInfo.navigationInfo;
            navInfo.pathGroup.pop();
            let idObj = {
                id: navInfo.questionnairePathId,
                pathGroup: navInfo.pathGroup,
                answerIndex: navInfo.answerIndex,
                wizardStep: navInfo.wizardStep,
                ordering: navInfo.ordering,
            };

            let param = {
                taxYear: this.$ledoconfig.getCookieStorage("taxYear"),
                ordering: navInfo.ordering,
                loadNotification: true,
                id: idObj,
            };

            this.$stateProvider.go("home.questionnaire", param, {reload: true});
        }
    }

    export class InteractiveReportComponent implements ng.IComponentOptions {
        public templateUrl: string;
        public controllerAs: string;
        public controller: any;

        constructor() {
            this.templateUrl = "/app/components/InteractiveReport/interactivereport.html";
            this.controllerAs = "model";
            this.controller = [
                "$state",
                "InteractiveReportService",
                "$stateParams",
                "$ledoconfig",
                // "$scope",
                // "$rootScope",
                // "$contextInject",
                InteractiveReportComponentController];
        }
    }

}
angular.module("LedoApp.components.interactivereport")
    .component("interactiveReportComponent", new LedoApp.DeletedItemReport.InteractiveReportComponent());


